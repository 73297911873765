import React from 'react';
import { Row, Col } from 'antd';
import AboutTitle from '../../AboutTitle';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `Hello !! My name is Nguyen Duy Ta. I'm a full stack web developer who is
    passionate about various web technologies. I like to experiment with different web
    technologies. I have an experience of nearly 7 years working with Ruby on Rails.
    Now, I working on Japan startup company. Check my blog which I update every week for some cool notes on web technologies and about my life in Japan.`,
  paraTwo: `Experienced Information Technology Specialist with a demonstrated history of working in the information technology and services industry.
  Skilled in Ruby on Rails, React, SQL, PHP, jQuery, Cascading Style Sheets (CSS).
  Successfully designed, developed, and launched many Ruby on Rails applications from eCommerce websites to production management applications.
  Strong information technology professional with a Bachelor of Engineering - BE focused in Information Technology from Vietnam National University, Hanoi.`,
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Nguyen Duy Ta', 'twelve', 'xedapkhongyen', 'FullStack developer', 'Javascript', 'ReactJS', 'NodeJS', 'Gatsby', 'technology']}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTitle
            img="location.png"
            height={60}
            alt="location image"
            textH4="Born and bought up in"
            textH3="Ba Vi, Hanoi, Vietnam"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTitle
            img="coffee.png"
            alt="coffee image"
            textH4="Living at"
            textH3="Tokyo, Japan"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTitle
            img="soccer.png"
            alt="soccer image"
            textH4="Love soccer"
            textH3="Soccer for life"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>{/* blank */}</Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTitle
            img="graduation.png"
            alt="graduation image"
            textH4="Vietnam National University, Hanoi"
            textH3="Information Technology"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
